import {
  DEV_COMMUNITY_COMPANY_PROFILE_URL,
  GITHUB_ORG_URL,
  PAGE_ROUTES, REDDIT_COMMUNITY_URL,
  TWITTER_COMPANY_PROFILE_URL,
} from '~/constants'

import { FooterItemProps, FooterIconProps } from './types'

export const useFooterItems = ({ jobsCount }: { jobsCount?: number }) => {
  const httpieLinks: FooterItemProps[] = [
    { href: PAGE_ROUTES.DESKTOP, label: 'Desktop' },
    { href: PAGE_ROUTES.CLI, label: 'Terminal' },
    { href: PAGE_ROUTES.AI, label: 'AI' },
    { href: PAGE_ROUTES.DOCS, label: 'Docs' },
    { href: PAGE_ROUTES.DOWNLOAD, label: 'Download' },
  ]

  const companyLinks: FooterItemProps[] = [
    { href: PAGE_ROUTES.ABOUT, label: 'About' },
    { href: PAGE_ROUTES.BLOG, label: 'Blog' },
    { href: PAGE_ROUTES.JOBS, label: 'Jobs', count: jobsCount },
  ]

  const bottomLinks: FooterItemProps[] = [
    { href: PAGE_ROUTES.TERMS, label: 'Terms & Conditions' },
    { href: PAGE_ROUTES.PRIVACY, label: 'Privacy Policy' },
  ]

  const socialIcons: FooterIconProps[] = [
    {
      title: 'Twitter',
      icon: 'twitter',
      href: TWITTER_COMPANY_PROFILE_URL,
      external: true,
    },
    {
      title: 'GitHub',
      icon: 'github',
      href: GITHUB_ORG_URL,
      external: true,
    },
    {
      title: 'Discord',
      icon: 'discord',
      href: PAGE_ROUTES.DISCORD,
      external: true,
    },
    {
      title: 'DEV Community',
      icon: 'devcommunity',
      href: DEV_COMMUNITY_COMPANY_PROFILE_URL,
      external: true,
    },
    {
      title: 'Reddit',
      icon: 'reddit',
      href: REDDIT_COMMUNITY_URL,
      external: true,
    },
  ]

  return { httpieLinks, companyLinks, bottomLinks, socialIcons }
}
