import { ChangeEvent, useCallback } from 'react'
import clsx from 'clsx'

import Icon from '~/components/Icon/Icon'
import { MAILCHIMP_FORM_ACTION_URL, useNewsletter } from '~/lib/newsletter'

import { TAGS } from './constants'
import { NewsletterFormProps } from './types'
import styles from './NewsletterForm.module.sass'

const NewsletterForm: React.FC<NewsletterFormProps> = ({
                                                         variant = 'base',
                                                         tag,
                                                         trackLabel,
                                                         className,
                                                         placeholder = 'Enter email address',
                                                         ...props
                                                       }) => {
  const { email, setEmail, submitted, handleSubmit } = useNewsletter({ submitEventLabel: trackLabel })

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value)
    },
    [setEmail],
  )

  return (
    <form
      onSubmit={handleSubmit}
      method='post'
      className={clsx(styles.form, className)}
      action={MAILCHIMP_FORM_ACTION_URL}
      id='mc-embedded-subscribe-form'
      name='mc-embedded-subscribe-form'
      target='_blank'
      noValidate
      autoComplete='off'
      {...props}
    >
      {tag && <input type='hidden' name='tags' value={TAGS[tag]} />}
      <input
        name='EMAIL'
        type='email'
        placeholder={placeholder}
        value={email}
        onChange={handleChange}
        className={clsx(styles.textInput, styles[variant], submitted && styles.submitted)}
        data-gramm_editor='false'
        data-lpignore='true' // LastPass
        autoComplete='off'
        required
      />
      <button className={clsx(styles.button, variant && styles[variant])} type='submit'>
        <Icon
          name={submitted ? 'checkmark' : 'rightarrow'}
          className={clsx(styles.icon, submitted && styles.submitted)}
        />
      </button>

      <div style={{ position: 'absolute', left: -5000 }} aria-hidden='true'>
        <input type='text' name='b_99f5ce2d00a123af1593451c6_61924eb759' tabIndex={-1} defaultValue='' />
      </div>
    </form>
  )
}

export default NewsletterForm
