import { ComponentType, useMemo } from 'react'

import { HttpieLogoLockup, PieLogoSymbol } from './assets'
import type { LogoVariant } from './types'

const logoVariants: Record<LogoVariant, ComponentType> = {
  lockup: HttpieLogoLockup,
  symbol: PieLogoSymbol,
}

export const useLogo = (variant: LogoVariant): ComponentType => {
  return useMemo(() => logoVariants[variant], [variant])
}
