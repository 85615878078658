import clsx from 'clsx'
import React from 'react'

import NewsletterForm from '~/components/NewsletterForm/NewsletterForm'
import Logo from '~/components/Logo/Logo'
import SocialIcon from '~/components/SocialIcon/SocialIcon'
import ThemeToggle from '~/components/ThemeToggle/ThemeToggle'
import Text from '~/components/Text/Text'
import Link from '~/components/Link/Link'
import Count from '~/components/Count/Count'
import { PAGE_ROUTES } from '~/constants'
import { FetchedLayoutProps } from '~/lib/layout'
import { FCWithChildren } from '~/lib/types'

import { FooterItemProps, FooterSectionProps } from './types'
import { useFooterItems } from './useFooterItems'
import styles from './Footer.module.sass'

const Footer: React.FC<FetchedLayoutProps> = ({ jobsCount }) => {
  const { companyLinks, httpieLinks, bottomLinks, socialIcons } = useFooterItems({ jobsCount })

  const SocialIcons = () => (
    <ul className={styles.icons}>
      {socialIcons.map((item) => (
        <li key={item.icon}>
          <SocialIcon {...item} trackLabel='from-footer' />
        </li>
      ))}
    </ul>
  )

  const SectionHeading: FCWithChildren = ({ children }) => (
    <Text variant='subheading' fontSize={[16, 14, 16]} className={styles.sectionHeading}>
      {children}
    </Text>
  )

  const renderLinksSection = ({ label, items }: FooterSectionProps) => (
    <ul className={styles.section}>
      <SectionHeading>{label}</SectionHeading>

      {items.map(({ label, href, external, count }) => (
        <li key={label}>
          <Text fontSize={[16, 14, 16]} className={styles.sectionItem}>
            <Link
              href={href}
              external={external}
              className={styles.link}
              data-track={`footer:go-to-${href.replace('/', '')}`}
            >
              {label}
              {count && <Count count={count} className={styles.count} />}
            </Link>
          </Text>
        </li>
      ))}
    </ul>
  )

  const renderBottomLink = ({ label, href, external }: FooterItemProps) => (
    <li key={label}>
      <Text fontSize={14} className={styles.bottomLink}>
        <Link
          href={href}
          external={external}
          className={styles.link}
          data-track={`footer:go-to-${href.replace('/', '')}`}
        >
          {label}
        </Link>
      </Text>
    </li>
  )

  return (
    <footer className={clsx(styles.footer)}>
      <div className='site-container site-grid'>
        <div className='col-start-1 col-end-2 md:col-span-2 lg:col-span-3'>
          <Link href={PAGE_ROUTES.HOME} data-track='footer:click-logo'>
            <Logo width={140} height={40} />
          </Link>
        </div>

        <div className='block md:hidden ml-auto row-start-1 col-start-2 col-end-3'>
          <ThemeToggle />
        </div>

        <div className='col-span-full mt-[30px] md:mt-0 md:col-start-3 md:col-end-6 lg:col-span-2'>
          {renderLinksSection({ label: 'HTTPie', items: httpieLinks })}
        </div>

        <div className='col-span-full mt-[30px] md:mt-0 md:col-start-6 md:col-end-8 lg:col-span-2'>
          {renderLinksSection({ label: 'Company', items: companyLinks })}
        </div>

        <div className='col-span-full mt-[30px] md:col-start-3 md:col-end-7 md:mt-5 lg:mt-0 lg:col-span-3'>
          <div className={styles.section}>
            <SectionHeading>Community</SectionHeading>
            <SocialIcons />
          </div>

          <div className={styles.section}>
            <Text fontSize={[20, 14, 16]} className={styles.newsletterHeading}>
              Join the occasional newsletter
            </Text>
            <NewsletterForm variant='footer' trackLabel='from-footer' data-testid='footer-newsletter-form' />
          </div>
        </div>

        <div className='hidden md:inline-flex md:row-start-1 md:col-start-8 lg:col-start-12'>
          <ThemeToggle />
        </div>
      </div>

      <div className='site-container site-grid'>
        <div className='row-start-1 col-span-full'>
          <ul className={styles.bottomLinks}>{bottomLinks.map(renderBottomLink)}</ul>
        </div>

        <div className='md:row-start-1 md:col-start-1 md:col-span-full'>
          <Text fontSize={14} className={styles.copyright}>
            © {new Date().getFullYear()}
            {' '}
            <strong>HTTPie, Inc.</strong>
            <br/>
            548 Market St, #26464, 94104 San Francisco,  CA
            <br/>
            HTTPie® is a registered trademark of HTTPie, Inc. All Rights Reserved.
          </Text>
        </div>
      </div>
    </footer>
  )
}

export default Footer
