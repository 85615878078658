import React from 'react'
import clsx from 'clsx'

import Icon from '~/components/Icon/Icon'
import Link from '~/components/Link/Link'

import { SocialIconProps } from './types'
import styles from './SocialIcon.module.scss'

const SocialIcon: React.FC<SocialIconProps> = ({ icon, href, external = false, title, trackLabel='' }) => {
  return (
    <Link href={href} external={external} data-track={`social:go-to-${icon}:${trackLabel}`} title={title}>
      <div className={clsx(styles.socialIconWrapper)}>
        <Icon name={icon} className={styles.socialIcon} />
      </div>
    </Link>
  )
}

export default SocialIcon
