import clsx from 'clsx'
import { useState } from 'react'

import { Theme, useTheme } from '~/lib/ThemeProvider'
import { Text } from '~/components/Text/Text'

import styles from './ThemeToggle.module.css'

const invertTheme = (theme: Theme) => (theme == Theme.DARK ? Theme.LIGHT : Theme.DARK)

const ThemeToggle = () => {
  const { setThemeSetting, themeSetting, effectiveTheme } = useTheme()
  const [prevThemeSetting, setPrevThemeSetting] = useState<Theme | null>()

  const getNextSetting = () => {
    let nextSetting: Theme
    if (!prevThemeSetting) {
      // 1st time changing — go to the opposite of the effective theme.
      nextSetting = invertTheme(effectiveTheme as Theme)
    } else {
      // Repeated clicks — cycle/bounce of edges side-ways.
      nextSetting = themeSetting != Theme.AUTO ? Theme.AUTO : invertTheme(prevThemeSetting as Theme)
    }
    return nextSetting
  }

  const handleToggle = () => {
    setPrevThemeSetting(themeSetting)
    setThemeSetting(getNextSetting())
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.container}
        onClick={handleToggle}
        role='button'
        tabIndex={0}
        data-track={`settings:set-theme:${getNextSetting()}`}
      >
        {Object.values(Theme).map((theme) => (
          <div key={theme} className={clsx(styles.radio, themeSetting === theme ? styles.active : '')} id={theme} />
        ))}
      </div>
      {Object.values(Theme).map((mode) => (
        <label key={mode} className={clsx(themeSetting === mode && styles.active)} htmlFor={mode}>
          <Text fontSize={10} as='span' className={styles.mode}>{`${mode.toLocaleUpperCase()} ${
            mode === Theme.AUTO ? '' : 'MODE'
          }`}</Text>
        </label>
      ))}
    </div>
  )
}

export default ThemeToggle
