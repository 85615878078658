// Based on <https://usehooks-ts.com/react-hook/use-local-storage>
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import useEventListener from '~/lib/useEventListener'

type SetValue<T> = Dispatch<SetStateAction<T>>

enum StorageType {
  Local = 'localStorage',
  Session = 'sessionStorage'
}

function useStorage<T>(key: string, initialValue: T, storageType: StorageType): [T, SetValue<T>] {
  // Get from storage, then parse stored json or return initialValue
  const readValue = (): T => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window[storageType].getItem(key)
      return item ? (parseJSON(item) as T) : initialValue
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error)
      return initialValue
    }
  }

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue)

  // Return a wrapped version of useState's setter function that persists the new value to storage.
  const setValue: SetValue<T> = (value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window == 'undefined') {
      console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`)
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value

      // Save to storage
      window[storageType].setItem(key, JSON.stringify(newValue))

      // Save state
      setStoredValue(newValue)

      // We dispatch a custom event so every useStorage hook are notified
      window.dispatchEvent(new Event('local-storage'))
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error)
    }
  }

  useEffect(() => {
    setStoredValue(readValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStorageChange = () => {
    setStoredValue(readValue())
  }

  // this only works for other documents, not the current one
  useEventListener('storage', handleStorageChange)

  // this is a custom event, triggered in writeValueToLocalStorage
  // See: useStorage()
  useEventListener('local-storage', handleStorageChange)

  return [storedValue, setValue]
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch (error) {
    return undefined
  }
}

export function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
  return useStorage(key, initialValue, StorageType.Local)
}

export function useSessionStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
  return useStorage(key, initialValue, StorageType.Session)
}
