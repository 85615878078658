import clsx from 'clsx'
import React from 'react'

import styles from './Count.module.sass'

interface Props {
  count?: number
  className?: string
}

const Count: React.FC<Props> = ({ count, className }) => <span className={clsx(styles.count, className)}>{count}</span>

export default Count
