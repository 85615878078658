import { useLogo } from './useLogo'
import { Props } from './types'

const Logo: React.FC<Props> = ({ variant = 'lockup', width, height, className }) => {
  const LogoSvg = useLogo(variant)

  if (!LogoSvg) {
    return null
  }

  return (
    <div style={{ width, height }} className={className}>
      <LogoSvg />
    </div>
  )
}

export default Logo
