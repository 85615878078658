import React, { HTMLAttributes, useState, useEffect } from 'react'
import clsx from 'clsx'

import { Breakpoint, BREAKPOINTS, useBreakpoint } from '~/lib/viewport'
import { FCWithChildren } from '~/lib/types'

import { TextVariant, TextTag, TextProps } from './types'
import styles from './Text.module.css'

const baseVariantTag: Record<TextVariant, TextTag> = {
  headline: 'h1',
  mono: 'code',
  subheading: 'h2',
  body: 'p',
  caption: 'span',
}

const getVariantClass = (variant: TextVariant, fontSize: number): HTMLAttributes<HTMLDivElement>['className'] => {
  // According to the Styleguide, all the fonts above 48pt (64px) should use tight line height
  const bigFontSize = fontSize > 64

  switch (variant) {
    case 'headline':
      return styles.headline
    case 'mono':
      return styles.mono
    case 'subheading':
      return bigFontSize ? styles.bigSubheading : styles.smallSubheading
    case 'body':
      return bigFontSize ? styles.bigBody : styles.smallBody
    case 'caption':
      return bigFontSize ? styles.bigCaption : styles.smallCaption
  }
}

// Get proper font size for current breakpoint
const getCurrentFontSize = (fontSize: number | number[], breakpoint: Breakpoint): number => {
  if (typeof fontSize !== 'object') {
    return fontSize
  }

  const breakpointIndex = BREAKPOINTS.indexOf(breakpoint)

  if (breakpointIndex < fontSize.length) {
    return fontSize[breakpointIndex]
  } else {
    return fontSize[fontSize.length - 1]
  }
}

/**
 * General purpose component for the typography.
 *
 * @param fontSize
 *  required, set typography font size in pixels. Can receive array of font sizes for different breakpoints, starting from the smallest one
 * @param variant default: body, set typography variant
 * @param as render text as a chosen html tag
 * @param className add or overwrite default classes
 */
export const Text: FCWithChildren<TextProps> = ({
  variant = 'body',
  fontSize,
  as,
  dangerouslySetInnerHTML,
  className,
  style,
  children,
  ...props
}) => {
  const currentBreakpoint = useBreakpoint()
  const [size, setSize] = useState<number>(getCurrentFontSize(fontSize, currentBreakpoint))

  useEffect(() => {
    setSize(getCurrentFontSize(fontSize, currentBreakpoint))
  }, [fontSize, currentBreakpoint])

  const AsElement = as || baseVariantTag[variant]
  const textProps = {
    className: clsx(styles.base, getVariantClass(variant, size), className),
    style: { fontSize: size, ...style },
    ...props,
  }

  if (dangerouslySetInnerHTML) {
    return <AsElement dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...textProps} />
  } else {
    return <AsElement {...textProps}>{children}</AsElement>
  }
}

export default Text
