import React, { useMemo, HTMLAttributes, useCallback } from 'react'
import clsx from 'clsx'
import { default as NextLink } from 'next/link'

import { HOMEPAGE_FULL_URL } from '~/constants'

import { LinkProps } from './types'
import styles from './Link.module.css'

type Props = LinkProps & HTMLAttributes<HTMLAnchorElement>

const Link: React.FC<Props> = ({
  children,
  className,
  href,
  external,
  underlined = false,
  onSamePageClick,
  ...props
}) => {
  const isHashLink = href.startsWith('#')
  const isExternal = useMemo(() => {
    if (external === undefined) {
      // Look for http* inside the provided url
      const externaHrefRegex = new RegExp('(http|https)://')
      const isInternal = href.startsWith(HOMEPAGE_FULL_URL)

      return !isInternal && externaHrefRegex.test(href)
    } else {
      return external
    }
  }, [external, href])

  const handleSamePageClick = useCallback(() => {
    if (window?.location.pathname === href) {
      onSamePageClick && onSamePageClick()
      window.scrollTo(0, 0)
    }
  }, [onSamePageClick, href])

  const externalLinkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
  }

  return (
    // Shallow must be true for same-page links with different hash.
    // Otherwise, browser back button might not work on some browsers.
    <NextLink href={href} shallow={isHashLink} scroll={!isHashLink}
        className={clsx(styles.link, underlined && styles.underlined, className)}
        onClick={handleSamePageClick}
        onKeyDown={handleSamePageClick}
        role='link'
        tabIndex={0}
        {...(isExternal && externalLinkProps)}
        {...props}
    >
        {children}
    </NextLink>
  )
}

export default Link
