import { useState, useCallback } from 'react'

import { useSessionStorage } from '~/lib/useStorage'
import useTimeout from '~/lib/useTimeout'

import { trackGaEvent } from './ga-events'

export const MAILCHIMP_FORM_ACTION_URL =
  '//httpie.us16.list-manage.com/subscribe/post?u=99f5ce2d00a123af1593451c6&amp;id=61924eb759'
export const TRACK_NEWSLETTER_CATEGORY = 'social'
export const TRACK_NEWSLETTER_SUBMITTED_ACTION = 'subscribe-to-newsletter'
export const TRACK_NEWSLETTER_CONFIRMED_ACTION = 'confirm-newsletter-subscription'

const CLEAR_SUBMITTED_TIMEOUT = 1000
const FLAG_SUBSCRIBED_KEY = 'pie-newsletter-subscribed'
const FLAG_CONFIRMED_KEY = 'pie-newsletter-confirmed'
const LAST_SUBMITTED_EMAIL_KEY = 'pie-newsletter-last-submitted-email'

type SubscribedValue = boolean | undefined
type ConfirmedValue = boolean | undefined
type LastSubmittedEmailValue = string | undefined
type SubmittedValue = boolean
type EmailValue = string

interface Props {
  emailValue?: EmailValue
  submittedValue?: SubmittedValue
  submitEventLabel?: string
}

export const useNewsletter = ({ emailValue = '', submittedValue = false, submitEventLabel = '' }: Props = {}) => {
  const [submitted, setSubmitted] = useState<SubscribedValue>(submittedValue)
  const [email, setEmail] = useState<EmailValue>(emailValue)
  const [subscribed, setSubscribed] = useSessionStorage<SubscribedValue>(FLAG_SUBSCRIBED_KEY, undefined)
  const [confirmed, setConfirmed] = useSessionStorage<ConfirmedValue>(FLAG_CONFIRMED_KEY, undefined)
  const [lastSubmittedEmail, setLastSubmittedEmail] = useSessionStorage<LastSubmittedEmailValue>(
    LAST_SUBMITTED_EMAIL_KEY,
    undefined
  )

  const handleSubmit = useCallback(() => {
    setSubmitted(true)
    // Save email on submit. This can be further used on pages like /cofirm.
    email && setLastSubmittedEmail(email)
    trackGaEvent(TRACK_NEWSLETTER_CATEGORY, TRACK_NEWSLETTER_SUBMITTED_ACTION, submitEventLabel)
  }, [setSubmitted, setLastSubmittedEmail, email, submitEventLabel])

  // Clear submitted form after some time from submitting.
  useTimeout(
    () => {
      setSubmitted(false)
      setEmail('')
    },
    submitted ? CLEAR_SUBMITTED_TIMEOUT : null
  )

  return {
    handleSubmit,
    submitted,
    email,
    setEmail,
    subscribed,
    setSubscribed,
    confirmed,
    setConfirmed,
    lastSubmittedEmail,
  }
}
